import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { IPagination } from '../../core/models/api/pagination/pagination.model';
import { CityTaxPreset } from '../../models/objects/city-tax-presets';
import {
  CityTaxPresetsCreateRequest,
  CityTaxPresetsSearchRequest,
} from '../../models/requests/city-tax-presets.request';

export const CityTaxPresetsActions = createActionGroup({
  source: 'City Tax Presets',
  events: {
    'Load Request': props<{ searchParams: CityTaxPresetsSearchRequest }>(),
    'Load Success': props<{
      cityTaxPresets: CityTaxPreset[];
      pagination: IPagination;
    }>(),
    'Load Failure': props<{ error: any }>(),

    'Load Details Request': props<{
      id: number;
    }>(),
    'Load Details Success': props<{
      cityTaxPreset: CityTaxPreset;
    }>(),
    'Load Details Failure': props<{ error: any }>(),

    'Create Request': props<{
      cityTaxPreset: CityTaxPresetsCreateRequest;
    }>(),
    'Create Success': props<{
      cityTaxPreset: CityTaxPreset;
    }>(),
    'Create Failure': props<{ error: any }>(),

    'Edit Request': props<{
      cityTaxPreset: CityTaxPresetsCreateRequest;
      id: number;
    }>(),
    'Edit Success': props<{
      cityTaxPreset: CityTaxPreset;
    }>(),
    'Edit Failure': props<{ error: any }>(),

    'Delete Request': props<{
      id: number;
    }>(),
    'Delete Success': props<{
      id: number;
    }>(),
    'Delete Failure': props<{ error: any }>(),

    'Reset State': emptyProps(),
  },
});
