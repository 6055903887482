import { Action, createReducer, on } from '@ngrx/store';

import { CityTaxPresetsActions as fromActions } from './actions';
import * as fromState from './state';
import { parseConfigurationApiValueToConfigurationWithPriceTyped } from '../city-tax-configuration-store/helpers';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { cityTaxPresets, pagination }) =>
    fromState.featureAdapter.setAll(cityTaxPresets, {
      ...state,
      pagination,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.loadDetailsRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadDetailsSuccess, (state, { cityTaxPreset }) =>
    fromState.featureAdapter.upsertOne(
      {
        ...cityTaxPreset,
        city_tax_configuration:
          parseConfigurationApiValueToConfigurationWithPriceTyped(
            cityTaxPreset.city_tax_configuration,
          ),
      },
      {
        ...state,
        isLoading: false,
        error: null,
      },
    ),
  ),
  on(fromActions.loadDetailsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.createRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.createSuccess, (state, { cityTaxPreset }) =>
    fromState.featureAdapter.addOne(cityTaxPreset, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.createFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.editRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.editSuccess, (state, { cityTaxPreset }) =>
    fromState.featureAdapter.upsertOne(cityTaxPreset, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.editFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.deleteRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.deleteSuccess, (state, { id }) =>
    fromState.featureAdapter.removeOne(id, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.deleteFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.resetState, () => fromState.initialState),
);

export function cityTaxPresetsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
