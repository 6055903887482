import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { generateSearchQuery } from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import {
  CityTaxPresetsCreateRequest,
  CityTaxPresetsSearchRequest,
} from '../models/requests/city-tax-presets.request';
import { CityTaxPreset } from '../models/objects/city-tax-presets';
import { IResponse } from '../core/models/response.model';

@Injectable({
  providedIn: 'root',
})
export class CityTaxPresetsService {
  constructor(private http: HttpClient) {}

  load(searchParams: CityTaxPresetsSearchRequest) {
    return this.http.get<IResponseSuccess<CityTaxPreset[]>>(
      `admin/city_tax_place_configurations?${generateSearchQuery(
        searchParams,
      )}`,
    );
  }

  loadDetails(id: number) {
    return this.http.get<IResponseSuccess<CityTaxPreset[]>>(
      `admin/city_tax_place_configurations/${id}`,
    );
  }

  create(request: CityTaxPresetsCreateRequest) {
    return this.http.post<IResponseSuccess<CityTaxPreset>>(
      `admin/city_tax_place_configurations`,
      request,
    );
  }

  edit(request: CityTaxPresetsCreateRequest, id: number) {
    return this.http.put<IResponseSuccess<CityTaxPreset>>(
      `admin/city_tax_place_configurations/${id}`,
      request,
    );
  }

  delete(id: number) {
    return this.http.delete<IResponseSuccess>(
      `admin/city_tax_place_configurations/${id}`,
    );
  }
}
