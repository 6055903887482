import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ErrorHandlerService } from '../../core/services/error-handler.service';

import { CityTaxPresetsActions as fromActions } from './actions';
import { CityTaxPresetsService } from '../../services/city-tax-presets.service';
import { Router } from '@angular/router';

@Injectable()
export class CityTaxPresetsStoreEffects {
  constructor(
    private dataService: CityTaxPresetsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private router: Router,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ searchParams }) =>
        this.dataService.load(searchParams).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadSuccess({
              cityTaxPresets: response.data,
              pagination: response.meta.pagination,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  loadDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadDetailsRequest),
      switchMap(({ id }) =>
        this.dataService.loadDetails(id).pipe(
          map(({ data }) =>
            fromActions.loadDetailsSuccess({
              cityTaxPreset: data[0],
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadDetailsFailure(error));
          }),
        ),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createRequest),
      switchMap(({ cityTaxPreset }) =>
        this.dataService.create(cityTaxPreset).pipe(
          map(({ data }) => {
            this.router.navigate(['admin/city-tax-presets']);
            return fromActions.createSuccess({
              cityTaxPreset: data[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createFailure(error));
          }),
        ),
      ),
    ),
  );

  edit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.editRequest),
      switchMap(({ cityTaxPreset, id }) =>
        this.dataService.edit(cityTaxPreset, id).pipe(
          map(({ data }) => {
            this.router.navigate(['admin/city-tax-presets']);
            return fromActions.editSuccess({
              cityTaxPreset: data[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.editFailure(error));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ id }) =>
        this.dataService.delete(id).pipe(
          map(() => fromActions.deleteSuccess({ id })),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteFailure(error));
          }),
        ),
      ),
    ),
  );
}
