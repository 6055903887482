import { createFeatureSelector, createSelector } from '@ngrx/store';

import { featureAdapter, State } from './state';
import { parseApiConfigurationToFormConfiguration } from '../../features/commons/city-tax-configurations-details/helpers';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getPagination = (state: State) => state.pagination;

export const selectState = createFeatureSelector<State>('cityTaxPresets');

export const selectAllItems =
  featureAdapter.getSelectors(selectState).selectAll;

export const selectById = (id: number) =>
  createSelector(selectState, (state) => state.entities[id]);

export const selectConfigurationFormValueByID = (id: number) =>
  createSelector(selectById(id), (configuration) =>
    parseApiConfigurationToFormConfiguration(
      configuration?.city_tax_configuration,
    ),
  );

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectPagination = createSelector(selectState, getPagination);
